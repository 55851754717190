<main>
  <mat-icon>anchor</mat-icon>
  <h1>West Coast Swing Syllabus</h1>

  <div class="button-group">
    <button mat-stroked-button
            color="primary"
            routerLink="/builder">Builder</button>
    <button mat-stroked-button
            color="primary"
            routerLink="/library">Library</button>
  </div>
</main>
